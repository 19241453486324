<template>
<div id="outter-background"><!-- play-container -->
  <component :is="layout">
    <slot />
  </component>
    <div id="logo-space" >PLAYSPACES</div>
    <div id="floating-space-man"><img src="/img/elements/play/floating_space_man.svg" width="277" height="218"/></div>
    <div id="floating-planet"><img src="/img/elements/play/floating_planet.svg" width="192" height="269"/></div>
    <div id="floating-alien"><img src="/img/elements/play/floating_alien.svg" width="111" height="162"/></div> 
    <cookie-law theme="playspaces">
        <div slot-scope="props" class="cookie-center">
          <div class="Cookie__content d-inline-block  align-top">
          {{$t('cookie.message')}} 
          {{$t('cookie.readmore')}} <a href="https://www.playspaces.co/privacy-policy" target="_blank">{{$t('global.privacypolicy')}}</a>.
          <br/><br/>
          </div>
          <div class="d-inline-block  align-top">
            <b-button pull variant="primary" @click="props.accept">
              <div class="confirm-icon ps-icons"></div>{{$t('cookie.accept')}}
            </b-button>
          </div> 
        </div>      
    </cookie-law> 
</div>
</template>

<script>
import { mapState } from 'vuex'
import CookieLaw from 'vue-cookie-law'
// This is the master layout for all pages.
// Default layout is the welcome layout (logged out)
const DefaultLayout = 'welcome'
export default {
  name: "masterlayout",
  components: { CookieLaw },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || DefaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    },
    ...mapState(['userData']),    
    ...mapState(['activeProfile'])     
  },
  watch: {
  'activeProfile':{
      handler (newVal){
        if(this.userData?.email !== null && this.activeProfile !== null){
          this.setSentryData()
        }
      }
    }
  },
  methods: {
    async getLocalDate() {
      await this.getLocationData();
    },
    setSentryData() {
      this.$sentry.setUser({ email: this.userData.email });
      this.$sentry.setTag("user_profile_id", this.activeProfile.userProfileId);
      this.$sentry.setTag("profile_type", this.activeProfile.type);
      this.$sentry.setTag("country", this.userData.country);
    },
    goHome() {
      this.$router.push('/');
    }
  },
  mounted() {
    this.getLocalDate();
  } 
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';
#outter-background {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
#logo-space {    
    bottom: 0px;
    font-family: 'Lexend Zetta', sans-serif;
    font-size: 16px;  
    font-weight: 400;
    color: $ps-purple;
    margin: 35px auto;
    width: 100%;
    text-align: center;
    position: absolute;
  }
  #floating-space-man {
    position: absolute;
    bottom: 50px;
    right: -140px;
    z-index: 10;
  }
  #floating-planet {
    position: absolute;
    top: 127px;
    left: -45px;
    z-index: 20;
  }
  #floating-alien {
    position: absolute;
    top: 436px;
    left: 37px;
    z-index: 30;
  }

</style>