import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import defaultState from './defaultState'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],  
  state: defaultState.defaultState(),
  getters: getters,
  mutations: mutations,
  actions: actions
})