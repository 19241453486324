import axios from 'axios'
import * as firebase from '../services/firebase'
import router from '../routes/index'

const apiPath = process.env.VUE_APP_FIREBASE_FUNCTIONS+'api/';

const api = {
    user: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/user/",
    profile: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/profile/",
    profiles: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/profiles/",
    rooms: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/rooms/",
    room: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/room/",
    invite: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/invite/",
    join: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/join/",
    codes: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/invitecode/",
    logevent: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/logevent/", 
    feedback: process.env.VUE_APP_FIREBASE_FUNCTIONS + "api/feedback/",
    hubspot: process.env.VUE_APP_FIREBASE_FUNCTIONS + "hubspot/" 
}

const logError = (error) => {
  console.log("error: " + error)
}

const psapi = axios.create({
  baseURL: process.env.VUE_APP_FIREBASE_FUNCTIONS+'api/',
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
});

export default {
    async login({ dispatch, commit, state, getters }, form) {
      try {
        await firebase.auth.signInWithEmailAndPassword(form.email, form.password)
        .then(async () => {
            await dispatch('getUserData');    
            await dispatch('getRooms'); 
            await dispatch('getProfiles');  
            await commit('setActiveProfile', getters.masterProfile); 
            dispatch('eventLog', {
              type: "auth",
              action: "login",
              content: state.currentIpData
            });
            return null;
        })
      } catch (error){
        return error; 
      } 
    },
    async reAuthUser({ dispatch },form){
      try {      
        let user = firebase.auth.currentUser;
        let credential = firebase.altAuth.EmailAuthProvider.credential(
          user.email, // references the user's email address
          form.password
        );      
        user.reauthenticateWithCredential(credential)
        .then(async () => {
          await dispatch('getToken');   
        });
      } catch (error) {
        return error.message; 
      } 
    },      
    async logout({ dispatch, commit, state }) {
        if(firebase.auth.currentUser) {
          dispatch('eventLog', {
            type: "auth",
            action: "logout",
            content: state.currentIpData
          });   
          await firebase.auth.signOut()
          .catch(function(error) {
            return error.message;
          });   
          commit('resetState');
          //localStorage.clear();
        }
        // TODO move logged out redirect to middleware
        router.push('/');   
      },    
      async forgotPassword({ dispatch }, form){
        // Fires off reset password email from Firebase Auth
        await firebase.auth.sendPasswordResetEmail(form.email)
        .catch(function(error) {
          return error.message;
        });
      },
      async checkPassword({ dispatch }, form) {
        let user = firebase.auth.currentUser;
        let cred = firebase.altAuth.EmailAuthProvider.credential(user.email, form.password);
        let result = await user.reauthenticateWithCredential(cred)
        .catch(function(error) {
          //console.log("error code: "+ error.code+" error msg: "+error.message)
          return error;
        });
        return result;
      },     
      async getUserData({ commit, getters, dispatch, state }) {
        // Pulls user data from Firestore and loads it into state
        await dispatch('getToken'); 
        let user = firebase.auth.currentUser;
        let userData = await axios.get(api.user + 
          '?token=' + getters.token)
        .catch(function(error) {
          //console.log(error);
          return error.message;
        });  
        // Loads data from the Auth into the user object.           
        userData = userData.data;
        userData.email = user.email;
        userData.id = user.uid;
        // Set user data in state
        commit('setUserData', userData); 
      },  
      async getRooms({ commit, getters, dispatch, state }) {
        let user = firebase.auth.currentUser;
        await dispatch('getToken'); 
        let Rooms = await axios.get(api.rooms + 
          '?token=' + getters.token)
        .catch(function(error) {
          //console.log(error);
          return error.message;
        }); 
        Rooms = Rooms.data;
        commit('setUserRooms', Rooms);
      },   
      async getProfiles({ commit, getters, dispatch, state }) {
        let user = firebase.auth.currentUser;
        await dispatch('getToken'); 
        let Profiles = await axios.get(api.profiles + 
          '?token=' + getters.token)
        .catch(function(error) {
          //console.log(error);
          return error.message;
        });  
        Profiles = Profiles.data;
        commit('setProfiles', Profiles);    
      }, 
      async getToken({ commit, state}){
        let token = await firebase.auth.currentUser.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
          return idToken;
        })
        .catch(function(error) {
          logError(error);
          return error.message;
        });
        //console.log(token);
        commit('setToken', token); 
      },
      async signUpCode({ commit, getters, dispatch, state}, codeId){
        // No token as it's a public page
        const resultSet = await axios.get(api.codes + codeId + 
          '/')
        .catch(function(error) {
          //console.log(error);
          return error.message;
        });  
        commit('setSignUp', resultSet.data); 
      },      
      async getInviteJoin({ commit, getters, dispatch, state}, inviteId) {
        try {
          // No token as it's a public page
          let results = await axios.get(api.join + inviteId + '/');
          commit('setInvite', results.data); 
          return null; 
        } catch (error) {
          //console.log(error);
          return error.message;
        }
        
      },
      async getInvite({ commit, getters, dispatch, state}, inviteId){   
        await dispatch('getToken'); 
        const resultSet = await axios.get(api.invite + inviteId + 
          '/?token=' + getters.token)
        .catch(function(error) {
          //console.log(error);
          return error.message;
        });  
        return resultSet.data;
      },       
      async getRoom({ commit, getters, dispatch, state}, roomId){
        await dispatch('getToken'); 
        const resultSet = await axios.get(api.room + roomId + 
          '/?token=' + getters.token)
        .catch(function(error) {
          //console.log(error);
          return error.message;
        });    
        commit('setCurrentRoom', resultSet.data); 
      },      
      async getActivityData({ commit, dispatch, getters, state }){
        let roomId = state.currentRoom.id;
        await dispatch('getToken'); 
        // Retrieves all saves in a room
        let resultSet = await axios.get(api.room + roomId + 
          '/saves/?token=' + getters.token)
        .catch(function(error) {
          return error.message;
        }); 
        resultSet = resultSet.data; 
        commit('setSaveData', resultSet); 
      },
      /*
      async getActivityDatabyId({ commit, dispatch, getters, state }, saveID) {
        try {
          await dispatch('getToken'); 
          let roomId = state.currentRoom.id;
          let gameCache = await axios.get(api.room + 
            roomId + '/save/' + saveID + 
            '?token=' + getters.token)
          .catch(function(error) {
            return error.message;
          }); 
          let game = gameCache.data;
          await commit('setActivityCache', JSON.parse(game.content)); 
          await commit('setLoadedSave', game);
        } catch(error){
          console.log(error);
        }
      },*/
      async eventLog({ dispatch, getters, state }, contentData){
        await dispatch('getToken');
        const payload = {
          type: contentData.type,
          action: contentData.action,
          content: contentData.content,
        };
        await axios.post(api.logevent + '?token=' + getters.token, 
          payload)
        .catch(function(error) {
          logError(error);
          return error.message; 
        });
      },
      async sessionLog({ dispatch, getters, state }, contentData){
        /*await dispatch('getToken');
        await axios.post(api.room   + state.currentRoom.id + '/sessionlog/?token=' + getters.token, 
          contentData)
        .catch(function(error) {
          logError(error);
          return error.message; 
        });*/
      },  
      async userFeedback({ dispatch, getters, commit, state }, answer){
        await dispatch('getToken');
        let upId = state.userData.id + "/" + state.activeProfile.id;
        let payload = {
          userProfileId: upId,
          profileType: state.activeProfile.type,
          sessionType: state.feedback.sessionType,
          sessionRole: state.feedback.sessionRole,
          answer: answer
        };
        await axios.post(api.feedback +'?token=' + getters.token, 
          payload)
        .catch(function(error) {
          logError(error);
          return error.message; 
        });
        // sends feedback to Google Sheet
        /*
        let reply = await axios.post(process.env.VUE_APP_FIREBASE_FUNCTIONS +'feedback/', payload)
        .catch(function(error) {
          logError(error);
          return error.message; 
        }); */   
        //console.log(JSON.stringify(reply));
        // clears feedback 
       await commit('setFeedback', null); 
      },    
      async addPlayer({ dispatch, getters, state }) {
        await dispatch('getToken');
        // Loads the current Active Profile into guest property for the Active Room
        // ? Do we pull this from guestData prop?
        let upId = state.userData.id + "/" + state.activeProfile.id 
        const payload = {
          userProfileId: upId,
          name: state.activeProfile.name,
          profilePhoto: state.activeProfile.profilePhoto,
          type: state.activeProfile.type,
          host: state.activeProfile.host,
          status: "connecting"
        };
        if(state.guestData !== null){
          payload.inviteId = state.guestData.id;
        }
        const results = await axios.post(api.room + state.currentRoom.id + 
          '/add-player/?token=' + getters.token, 
          payload)
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },  
      async removePlayer({ dispatch, getters, commit, state }, player) {
        await dispatch('getToken');
        let payload = {};
        // Clears player from room
        if(player) {
          payload = player;
        } else {
          payload = {
            userProfileId: state.userData.id + "/" + state.activeProfile.id,
            name: state.activeProfile.name,
            profilePhoto: state.activeProfile.profilePhoto,
            type: state.activeProfile.type,
            host: state.activeProfile.host,
            status: null
          };
        }
        const results = await axios.post(api.room + state.currentRoom.id + 
          '/remove-player/?token=' + getters.token, 
          payload)
        .catch(function(error) {
          logError(error);
          return error.message;
        });
        //commit('setCurrentRoom', null); 
      },  
      async removePlayers({ dispatch, getters, commit, state }) {
        await dispatch('getToken');
        //clears all players from a room.
        const results = await axios.post(api.room + state.currentRoom.id + 
          '/remove-players/?token=' + getters.token)
        .catch(function(error) {
          logError(error);
          return error.message;
        });
        //commit('setCurrentRoom', null); 
      },  
      async closeRoom({ dispatch, getters, state }) {
        await dispatch('getToken');
        const results = await axios.post(api.room + state.currentRoom.id + 
          '/close/?token=' + getters.token)
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },  
      async openRoom({ dispatch, getters, state }) {
        await dispatch('getToken');
        const results = await axios.post(api.room + state.currentRoom.id + 
          '/open/?token=' + getters.token)
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      }, 
      async betaSignUp({ dispatch }, form) {
        try {
          const payload = {
            firstname: form.firstname,
            lastname: form.lastname,
            email: form.email,
            zipcode: form.zipcode,
          }          
          const results = await axios.post(api.hubspot + "intake/", payload)
          .catch(function(error) {
            logError(error);
            return error.message;
          });          
          return results; 
        } catch (error) {
          return error;
        }
      },             
      async addProfile({ dispatch, getters, state }, form){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let timeStamp = new Date().getTime();
        const headers ={
          authorization: getters.token
        };
        const payload = {
          name: form.name,
          profilePhoto: form.profilePhoto,
          birthday: form.birthday,
          type: form.type,
        }
        let result = await psapi.post(api.profile + '?token=' + getters.token, payload, {
            headers: {
              authorization: getters.token
            }
          })
          .catch(function(error) {
            logError(error);
            return error.message;
        }); 
        await dispatch('getProfiles'); 
        let profile = {
          id: result.data,
          name: form.name
        };    
        await dispatch('addRoom', profile);   

      }, 
      async addRoom({ dispatch, getters, state }, profile){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let activeProfileID = profile.id;
        let roomName = profile.name + "'s Room"; 
        let upId = user.uid + "/" + activeProfileID;
        let payload = {
          profileId: activeProfileID,
          userProfileId: upId,
          name: roomName,
        };
        
        let result = await axios.post(
          api.room +
          '?token=' + getters.token, payload)
          .catch(function(error) {
            logError(error);
            return error.message;
        }); 
     
        await dispatch('getRooms');
      },
      async saveActivity ({ commit, dispatch, getters, state }){
        try {
          const saveLimit = 3;
          // TODO overwrite local storage for updated activity
          // TODO refactor cache to mirror save objects.
          await dispatch('getToken');
          // Saves Activity Data
          let roomId = state.activeProfile.roomId;
          let savesList = getters.saves || null;
          let saveId = state.activityCache.saveId;

          if(savesList !== null) {
            if(savesList.length >= saveLimit) {
              // max saves reached overwrite existing
              if(saveId === null) {
                // new save overwrite an oldest save in list otherwise it will just overwrite itself if editing.
                saveId = savesList[saveLimit-1].id;
              } 
            } 
          }
          //! fix this as it now mirrors this structure 
          let content = JSON.stringify(state.activityCache.content);
          let payload = {
            saveId: state.activityCache.saveId,
            name: state.activityCache.name,
            players: state.activityCache.players,
            assetPath: state.activityCache.assetPath,
            thumbPath: state.activityCache.thumbPath,
            content: content,
            activityName: state.activityCache.activityName,
          };
          //console.log("payload: "+ JSON.stringify(payload));
          await axios.post(api.room + roomId + '/save?token=' + getters.token, payload)
          .then(() => {
            // Clears Activity Cache after it's saved.
            commit('setActivityCache', null);
          })
          .catch(function(error) {
              logError(error);
              //return error.message;
          });
        } catch(error){
          logError(error);
        }
      },         
      async addInvite({ dispatch, getters }, form){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let host = {
          userId: user.uid, 
          name: form.hostname,
          profileId: form.profileId,
          roomId: form.roomId,
        }
        let guest = {
          userId: null, 
          profileId: null,  
          name: form.guestname,   
          email: form.guestemail,
      }
        let timeStamp = new Date().getTime();
        let startDateTime = {};
        let endDateTime = {};
        startDateTime = form.startdate + " " + form.starttime;
        endDateTime = form.startdate + " " + form.endtime;
        const payload = {
          host: host,
          guest: guest,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          timezone: form.timezone,
          status: form.status         
        }     
        let response = await axios.post(
          api.invite + '?token=' + getters.token, payload) 
          .catch(function(error) {
            logError(error);
            return error.message;
        });        
        return response.data;
      },  
      async addPIN({ dispatch, getters }, form){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let updates = {
          parentalPin: form.parentalPin,
        };
        await axios.put(api.user + '?token=' + getters.token, updates)
        .catch(function(error) {
          logError(error);
          return error.message;
        });    
        await dispatch('getUserData');
      }, 
      async updateInvite({ dispatch, getters, commit }, data){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let inviteId = data.id

        let updates = {
          status: data.status
        };

        await axios.put(api.invite + inviteId + '?token=' + getters.token, updates)
        .catch(function(error) {
          logError(error);
          return error.message;
        });    
        //await commit('setActivity', activityName);
      },       
      async updateActivity({ dispatch, getters, commit }, activityName){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let roomId = getters.currentRoom.id;
        let updates = {
          currentActivity: activityName,
        };
        await axios.put(api.room + roomId + '?token=' + getters.token, updates);
        //! We are getting non error rejects here. Unclear what's driving it.
        /*.catch(function(error) {
          logError(error);
          return error.message;
        });*/
        await commit('setActivity', activityName);
      }, 
      async updateRoomLoadedSave({ dispatch, getters, commit }, saveID){
        await dispatch('getToken');
        let user = firebase.auth.currentUser; 
        let roomId = getters.currentRoom.id;

        let updates = {
          loadedSave: saveID,
        };
        await axios.put(api.room + roomId + '?token=' + getters.token, updates)
        .catch(function(error) {
          logError(error);
          return error.message;
        });
        await commit('setLoadedSave', saveID);    
      },                       
      async addUser({ dispatch, getters, state }, form) {
        try {
          let payload = {
            email: form.email,
            password: form.password,
            invitecode: form.invitecode,
            firstname: form.firstname,
            lastname: form.lastname,
            tosaccept: form.tosaccept,
            newsletter: form.newsletter,
            country: form.country,
            timezone: form.timezone,
            language: form.language,
            type: form.type,
            referralId: form.referralId,
            origin: form.origin,           
            status: 'active',
            membership: 'private-beta',
            parentalPin: null,
          };
          const results = await axios.post(api.user, payload)
          .then(async (results) =>{
            
            return results;
          });
          if(results.status == 200){
            await firebase.auth.signInWithEmailAndPassword(form.email, form.password);
            await dispatch('getUserData');    
            await dispatch('welcomeEmail');
            await dispatch('newsletterAdd');           
          }
          return results;
        }
        catch (error) {
          logError(error.response);
          return error.response;
        }
      },
      /*async signUpUsed({ dispatch, getters }, codeId){
        await axios.post( api.invitecode + codeId + "/used?token=" + getters.token )
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },*/
      // TODO handle this better... it's messy
      async hubspotUpdate({ dispatch, getters }){
        await dispatch('getToken');  
        await axios.post( process.env.VUE_APP_FIREBASE_FUNCTIONS + "hubspot/outbound/" + "?token=" + getters.token )
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },
      async welcomeEmail({ dispatch, getters }) {
        await dispatch('getToken');  
        await axios.post( process.env.VUE_APP_FIREBASE_FUNCTIONS + "send/welcome/" + "?token=" + getters.token )
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },      
      async newsletterAdd({ dispatch, getters  }) {
        await dispatch('getToken');  
        await axios.post( process.env.VUE_APP_FIREBASE_FUNCTIONS + "newsletter/" + "?token=" + getters.token )
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },
      async newsletterSub({ dispatch, getters  }) {
        await dispatch('getToken');  
        await axios.post( process.env.VUE_APP_FIREBASE_FUNCTIONS + "newsletter/sub" + "?token=" + getters.token )
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },  
      async newsletterUnsub({ dispatch, getters }) {
        await dispatch('getToken'); 
        await axios.post( process.env.VUE_APP_FIREBASE_FUNCTIONS + "newsletter/unsub" + "?token=" + getters.token )
        .catch(function(error) {
          logError(error);
          return error.message;
        });
      },           
      async updateUser({ dispatch, getters}, form) {
        try {
          await dispatch('getToken');        
          let user = firebase.auth.currentUser; 
          //Re-auth the user's token per FB guidelines
          //TODO fix this as it required a separate import something to do with auth(). versus auth.
          let cred = firebase.altAuth.EmailAuthProvider.credential(user.email, form.oldpassword);
          
          await user.reauthenticateWithCredential(cred)
          .then(async () => {
            let updates = {
              firstname: form.firstname,
              lastname: form.lastname
            };
            await axios.put(api.user + '?token=' + getters.token, updates);
            // Update Password
            if(form.newpassword !== null) {
              user.updatePassword(form.newpassword);
            }
            // Update Email
            if(user.email !== form.email ) {     
              user.updateEmail(form.email)
              .catch ( error => {
                logError(error);
                return error;
              });     
            }   
            dispatch('hubspotUpdate');
          });
          // Refresh user data
          dispatch('getUserData', user);
          return null;
        }
        catch (error) {
          logError(error);
          return error;
        }
      },    
      async updateProfile({ dispatch, getters }, form){
        await dispatch('getToken');        
        let user = firebase.auth.currentUser; 
        let updates = {
          name: form.name,
          profilePhoto: form.profilePhoto,
          birthday: form.birthday,
          type: form.type,
        };

        await axios.put(api.profile + form.profileId + '?token=' + getters.token, updates)
        .catch(function(error) {
          logError(error);
          return error.message;
        }); 
        dispatch('getProfiles', user);  
      },      
      async updateParentalCtls({ dispatch, getters}, form){
        await dispatch('getToken');        
        let user = firebase.auth.currentUser; 
        if(form.newsletter === true) {
          dispatch('newsletterSub');
        } else {
          dispatch('newsletterUnsub');
        }
        let updates = {
          parentalPin: form.parentalPin,
          newsletter: form.newsletter,
        };
        await axios.put(api.user + '?token=' + getters.token, updates)
        .catch(function(error) {
          logError(error);
          return error.message;
        });  
        dispatch('getUserData', user);  
        dispatch('hubspotUpdate');
      },  
      async deleteProfile({ dispatch, getters }, profileId){
        await dispatch('getToken');        
        let user = firebase.auth.currentUser; 
        //let profileId = form.id;
        // Delete Profile
        await axios.delete(
          api.profile + profileId + '/?token=' + getters.token)
          .catch(function(error) {
            logError(error);
            return error.message;
        }); 
        // Delete Profile       
        await axios.delete(
          api.room + profileId + '/?token=' + getters.token)
          .catch(function(error) {
            logError(error);
            return error.message;
        }); 
        await dispatch('getRooms'); 
        await dispatch('getProfiles'); 
        // TODO delete saves
        // TODO delete sessions
        // TODO delete invites
      } 
};