import * as firebase from '../services/firebase'

export default function auth ({ to, from, next, store }) {
    try {    
        let redirectPath = to.path;
        if(!firebase.auth.currentUser){
            // TODO this seems to cause a lot of logic issues. Refactor it.
            return next({ name: 'Login', query: {redirect: redirectPath} });
        //} else if(to.path == "" || to.path == "/") {
            // if logged in redirect to home.
            //return next('/home');
        } else {
            return next();      
        }
    } catch (error) {
        console.log(error);
    }        
}