
export default {
  token: (state) => {
    if(state.token?.content) {
      return state.token.content;
    } else {
      return null;
    }
  },    
  userType: (state) => {
    if(state.userData != null) {
      return state.userData.type
    } else {
      return null;
    }
  },
  profileType: (state) => {
    if(state.activeProfile != null) {
      return state.activeProfile.type
    } else {
      return null;
    }
  },
  userProfileId: (state) => {
    let upId = state.userData.id + "/" + state.activeProfile.id;
    return upId;
  },
  appVersion: (state) => {
    return state.packageVersion;
  },
  currentDomain: (state) => {
    return state.currentDomain;
  },
  currentAppName: (state) => {
    return state.currentAppName;
  },  
  currentLocation: (state) => {
    if(state.currentIpData?.country){
      return state.currentIpData.country;
    } else {
      return null;
    }
  },
  currentIpData: (state) => {
    return state.currentIpData;
  },        
  currentTimezone: (state) => {
    return state.currentIpData.timezone;
  },      
  currentEnvironment: (state) => {
    return state.currentEnvironment;
  },    
  profileList: (state) => {
    return state.profiles.profiles;
  },
  feedback: (state) => {
    return state.feedback;
  },    
  profileCount: (state) => {
    if(state.profiles?.count){
      return state.profiles.count;
    } else {
      return null;
    }
  },
  masterProfile: (state) => {
    if(state.profiles) {
      let masterProfile = state.profiles.profiles.find(p => p.type === 'owner');
      return masterProfile;
    } else {
      return null;
    }
  },
  activitiesList: (state) => {
    return state.activitiesList;
  },
  activeProfile: (state) => {
    return state.activeProfile;
  },
  isHost: (state) => {
    if(state.activeProfile?.host){
      return true;
    } else {
      return false;
    }
  },  
  invitesList: (state) => {
    return state.invites;
  },
  players: (state) => {
    return state.players;    
  },    
  waiting: (state) => {
    return state.waiting;       
  },      
  currentRoom: (state) => {
    return state.currentRoom;
  },
  saves: (state) => {
    return state.saves.saves;  
  },
  savesCount: (state) => {
    if(state.saves?.count){
      return state.saves.count;
    } else {
      return null;
    }
  },
  currentActivity: (state) => {
    return state.currentActivity;      
  },
  activityCache: (state) => {
    return state.activityCache;      
  },
  thumbPath: (state) => {
    if (state.activityCache?.thumbPath) {
      return state.activityCache.thumbPath; 
    } else {
      return null;
    }
     
  },   
  loadedSave: (state) => {
    return state.loadedSave;      
  } 
};