import Vue from 'vue'; 
import TZ from '@/data/timezones.json'
import store from '@/store/index';

export default {
    data: function() {
        return {
            tz: TZ,
            timeOut: null,
            inactivityTimer: null
        }
    },
    computed: {
 
    },    
    created: function () {
       
    },
    methods: {
      tracking(data) {
        let payload = {};
        let cd1, cd2, cd3, cd4, cd5 = null;
        let userType = store.getters.userType;
        let profileType = store.getters.profileType;
        let profileCount = store.getters.profileCount;
        //let currentActivity = store.state.currentActivity;
        //let players = store.state.players;
        if(data.type === "page") {
          payload = {
            page_title: data.title,
            page_location: window.location.href,
            page_path: data.title            
          };  
        } else if(data.type === "event") {
          payload = {
            page_title: "",
            page_location: "",
            page_path: "",
            event_category: data.category          
          };           
          //payload.event_category = data.category;
          if(data.label != null){payload.event_label = data.label;} 
        }        
        // Profile type [adult, kid, user]
        if(profileType != null) {
          cd1 = profileType;
          payload.profile_type = cd1;
          this.$gtag.set('dimension1', cd1); 
        } 
        // User type [family or individual]
        if(profileCount != null) {
          if(profileCount > 2) {
            cd2 = "family";
          } else {
            cd2 = "individual";
          }    
          payload.user_type = cd2;
          this.$gtag.set('dimension2', cd2); 
        }
        // Set host / guest switching
        if(this.activeProfile != null) {
          if(this.activeProfile.host === false) {
            cd3 = "guest";
          } else {
            cd3 = "host";
          }
          payload.session_role = cd3;
          this.$gtag.set('dimension3', cd3);
        } 
        if(store.state.currentActivity !== null) {
          // Activity name
          cd5 = store.state.currentActivity ; 
          payload.activity_name = cd5;
          this.$gtag.set('dimension5', cd5);
        } else {
          this.$gtag.set('dimension5', null);          
        }
        // Solo or 2 player sessions
        if(store.state.players !== null) {
          cd4 = "solo"; 
          if(store.state.players.count >= 2) {
            cd4 = "2-player";  
          }
          payload.players = cd4;
          //this.$gtag.set('dimension4', cd4);
        } else {
          cd4 = null;          
        }  
        this.$gtag.set('dimension4', cd4);       
        // page view or event clicks
        if(data.type == "page") {            
          this.$gtag.pageview(payload);          
        }
        if (data.type == "event") {
          this.$gtag.event(data.action, payload);          
        }
      },
      logError(error) {
        //TODO fix this
        //this.$//sentry.captureException(error);
        console.log("Error Log: " + error);
      },
      logout() {
        store.dispatch('logout')
      },
      async switchProfile(profile){         
        await store.commit('setActiveProfile', profile);
      },
      validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      validPassword(password) {
        let re = /^(?=.*[0-9a-zA-Z]).{6,}$/
        return re.test(password);
      },
      async getLocationData() {
        // TODO swap with http://api.ipstack.com/71.247.247.107?access_key=852cfc8d48dbe6176c49d89ca2bb8846
        // properties will have different labels.
        // Time zone will require a subscription.
        let ipData = await fetch('https://ipapi.co/json/')
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data;
        })
        .catch(error => {
            this.error = error.message;
            this.logError(error);
        }); 
        if(ipData){
          await store.commit('setIpData', ipData);
        } else {
          // if the ip lookup fails
          let x = {
            country: "unknown",
            timezone: "America/New_York"
          };
          await store.commit('setIpData', x);          
        }
        let location = store.getters.currentLocation;
        if(location !== null){
          if(location != "US"){
            // console.log("Country unsupported: " + ipData.country);
            // ! this is not working as expected, seems like it filters out ppl in the US in some cases.
            // this.$router.push('/unsupported');
          }
        }        
        //return ipData;         
      },
      validPIN(pin){
        // using this for PIN and year validation.
        let re = /^\d{4}$/;
        return re.test(pin);
      },
      validDate(thedate){
        let date = this.$moment(thedate);
        return date.isValid();
      },
      validYear(year){ 
        let currentYear = new Date();
        currentYear = currentYear.getFullYear()
        let re =/^(19|20)\d{2}$/;
        let valid = false;
        if(re.test(year) && year < currentYear){
          valid = true;
        }
        return valid;
      },
      formatBirthday(month,day,year){
        month = this.leadingZeros(month);
        day = this.leadingZeros(day);
        let fullbirthday = year + "-" + month + "-" + day;
        return fullbirthday;
      },   
      ageCheck(birthday){
        let currentYear = new Date();
        let a = this.$moment(birthday);
        let b = this.$moment(currentYear);
        let age = b.diff(a, 'years', true);
        if( age >= 18 && age <= 150) { 
          return true;
        } else {
          return false;           
        }
      },   
      leadingZeros(n) {
        var numstring = n + "";
        while (numstring.length < 2) numstring = "0" + numstring;
        return numstring;
      },
      isDatePast(xDate, yDate){
        if (xDate.setHours(0, 0, 0, 0) <= yDate.setHours(0, 0, 0, 0)) {
          return true;
        }
        return false;        
      },
      tzLookup(name){
        let shortTZ = this.tz.find(r => {
          let i = r.utc.find(u => u === name)
          return i;
        });
        return shortTZ.abbr;
      },
      deviceSupport() {
        let supported = false;
        let name = this.$browserDetect.meta.name;
        if(name == "Chrome" || name == "Safari") {
          supported = true;
        }
        return supported;
      },
      isPhone() {
        let mobile = false;
        // device detection
        if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (window.innerWidth < 760)) { 
            mobile = true;
        }
        return mobile;
      },
      isLandscape(){
        let supported = false;
        if((window.innerHeight < window.innerWidth) || (window.innerWidth >= 1024)) {
          supported = true;
        }
        return supported;
      },
      async noWebcam(){
        let supported = true;
        const devices = await navigator.mediaDevices.enumerateDevices() || await navigator.getUserMedia || await navigator.webkitGetUserMedia || await navigator.mozGetUserMedia;
        if (!devices) {
          supported = false;
        }
        let videoInputs = devices.filter(device => device.kind === `videoinput`);
        if(videoInputs.length < 1) {
          supported = false;          
        }
        return supported;  
      },
      makeToast(msg) {
        //console.log("toast: " + msg)
        this.$bvToast.toast(msg, {
            autoHideDelay: 5000,
            appendToast: true,
            noCloseButton: false,
            solid: false
        })
    },          
    }
 }