import * as firebase from '../services/firebase'

export default function loggedIn ({ to, from, next, store }) {
    try {
        if(firebase.auth.currentUser) {
            if(store.state.guestData != null){
                return next('/selectprofile/');
            } else {
                return next('/home');
            }      
        } else {
            return next();   
        }
    } catch (error) {
        console.log(error);
    }
}