import Vue from 'vue';
import Router from 'vue-router';
import roomChecks from '../middleware/roomChecks.js';
import store from '../store/index'
import auth from '../middleware/auth';
import middlewarePipeline from './middlewarePipeline';
import setupSteps from '../middleware/setupSteps';
import loggedIn from '../middleware/loggedIn'; 
const Home = () => import(/* webpackChunkName: "Homescreen" */ '@/pages/home/index.vue');
const Spaces = () => import(/* webpackChunkName: "Spaces" */ '@/pages/play/spaces.vue');
const PSError = () => import(/* webpackChunkName: "Spaces" */ '@/pages/play/error.vue');
const Join = () => import(/* webpackChunkName: "Join" */ '@/pages/join/index.vue');
const Beta = () => import(/* webpackChunkName: "Join" */ '@/pages/join/beta.vue');
const Unsupported = () => import(/* webpackChunkName: "Join" */ '@/pages/join/unsupported.vue');
const Warnings = () => import(/* webpackChunkName: "Join" */ '@/pages/join/warnings.vue');
const Lobby = () => import(/* webpackChunkName: "Join" */ '@/pages/join/lobby.vue');
const Selectprofile = () => import(/* webpackChunkName: "Join" */ '@/pages/join/selectprofile.vue');
const Welcome = () => import(/* webpackChunkName: "Welcome" */ '@/pages/welcome/index.vue');
const AgeChecker = () => import(/* webpackChunkName: "Welcome" */ '@/pages/welcome/agechecker.vue');
const NotFound = () => import(/* webpackChunkName: "Welcome" */ '@/pages/error/404.vue');
const Offline = () => import(/* webpackChunkName: "Welcome" */ '@/pages/error/offline.vue');
const fbLogin = () => import(/* webpackChunkName: "Auth" */ '@/pages/auth/login.vue');
const fbLogout = () => import(/* webpackChunkName: "Auth" */ '@/pages/auth/logout.vue');
const Forgot = () => import(/* webpackChunkName: "Auth" */ '@/pages/auth/forgot.vue');
const SignUp_1 = () => import(/* webpackChunkName: "Auth" */ '@/pages/signup/step_1.vue');
const SignUp_2 = () => import(/* webpackChunkName: "Auth" */ '@/pages/signup/step_2.vue');
const SignUp_3 = () => import(/* webpackChunkName: "Auth" */ '@/pages/signup/step_3.vue');
const Onboarding = () => import(/* webpackChunkName: "Auth" */ '@/pages/signup/onboarding.vue');
const Waitlist = () => import(/* webpackChunkName: "Auth" */ '@/pages/signup/waitlist.vue');
const Confirmed = () => import(/* webpackChunkName: "Auth" */ '@/pages/signup/confirmed.vue');

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [  
      { 
        path: '*', 
        redirect: '/404' 
      }, 
      {
        path: '/404',
        name: '404',
        component: NotFound,
        meta: {
          title: 'Page not Found - Playspaces Beta',
          layout: 'basic',
        }
      },
      {
        path: '/offline',
        name: 'Offline',
        component: Offline,
        meta: {
          title: 'Offline - Playspaces Beta',
          layout: 'basic',
        }
      },               
      {
        path: '/',
        name: 'Welcome',
        component: Welcome,
        meta: {
          title: 'Welcome - Playspaces Beta',
          layout: 'welcome',
          middleware: [
            loggedIn,
          ]             
        }
      },    
      {
        path: '/agechecker',
        name: 'Agechecker',
        component: AgeChecker,
        meta: {
          title: 'Welcome - Playspaces Beta',
          layout: 'basic',
          middleware: [
            loggedIn,
          ]           
        }
      },                             
      {
        path: '/login',
        name: 'Login',
        component: fbLogin,
        meta: {
          layout: 'welcome',
          middleware: [
            loggedIn,
          ]  
        }
      },  
      {
        path: '/logout',
        name: 'Logout',
        component: fbLogout,
        meta: {
          layout: 'auth',
        }
      },            
      {
        path: '/forgot',
        name: 'Forgot',
        component: Forgot,
        meta: {
          layout: 'welcome',
        }
      },
      {
        path: '/signup/waitlist',
        component: Waitlist,
        meta: {
          title: 'Beta Waitlist - Playspaces Beta',
          layout: 'auth',          
        }
      }, 
      {
        path: '/signup/confirmed',
        component: Confirmed,
        meta: {
          title: 'Beta Confirmed - Playspaces Beta',
          layout: 'auth',          
        }
      },                 
      {
        path: '/signup/step1',
        component: SignUp_1,
        meta: {
          layout: 'auth',
          title: 'Sign Up',
          middleware: [
            setupSteps 
          ]  
        },
      },
      {
        path: '/signup/step2',
        component: SignUp_2,
        meta: {
          layout: 'auth',
          middleware: [
            setupSteps
          ]          
        },
      },
      {
        path: '/signup/step3',
        component: SignUp_3,
        meta: {
          layout: 'auth',
          middleware: [
            auth, setupSteps, 
          ]
        },
      }, 
      {
        path: '/signup/onboarding',
        component: Onboarding,
        meta: {
          layout: 'auth',
          middleware: [
            auth, setupSteps,  
          ]
        },
      },                                
      {
        path: '/signup',
        name: 'Signup',
        redirect: '/signup/step1', 
      },
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          layout: 'home',
          middleware: [
            auth,
          ]
        }
      },
      {
        path: '/join',
        name: 'Join',
        redirect: '/', 
      },         
      {
        path: '/join/:invite_id',
        name: 'Joins',
        component: Join,
        meta: {
          layout: 'join',
        }
      },
      {
        path: '/join/beta/:beta_id',
        name: 'Beta',
        component: Beta,
        meta: {
          layout: 'join',
        }
      },    
      {
        path: '/unsupported',
        name: 'Unsupported',
        component: Unsupported,
        meta: {
          layout: 'join',
        } 
      },         
      {
        path: '/warnings',
        name: 'Warnings',
        component: Warnings,
        meta: {
          layout: 'join',
        } 
      },     
      {
        path: '/join/:invite_id/lobby',
        name: 'Lobby',
        component: Lobby,
        meta: {
          layout: 'auth',
          middleware: [
            auth
          ]          
        }
      }, 
      {
        path: '/selectprofile',
        name: 'Selectprofile',
        component: Selectprofile,
        meta: {
          layout: 'auth',
          middleware: [
            auth
          ]          
        }
      },                     
      {
        path: '/play',
        name: 'Play',
        redirect: '/home'
      },    
      {
        path: '/play/space/:space_id',
        name: 'Spaces',
        component: Spaces,
        meta: {
          layout: 'play',
          middleware: [
            roomChecks,
          ]          
        }
      },
      {
        path: '/playspace/error',
        name: 'PSError',
        component: PSError,
        meta: {
          layout: 'basic',
          middleware: [
            auth
          ]          
        }
      }      
  ]
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
})
/*
router.beforeEach((to, from, next) => {
  //TODO connect this with database system->maintenance
  //const maintenaceStatus = this.$fb.systemCollection.doc(user.uid).get()
  const maintenaceStatus = false;
  if(maintenaceStatus == true ){
    if(to.path != "/offline"){
      next('/offline');
    } else {
      next();
    }
  } else {

})*/

export default router