import defaultState from './defaultState'

export default {
    resetState(state){
        Object.assign( state, defaultState.defaultState() );
      },
      setToken(state, val) {
        state.token = {
          content: val,
          created: new Date()
        }
      },    
      setIpData(state, val) {
        state.currentIpData = val
      },          
      setUserData(state, val) {
        state.userData = val
      },
      setNewEmail(state, val) {
        state.userData.email = val
      },
      setActiveProfile(state, val) {
        state.activeProfile = val
        if(val != null) {
          let profileRoom = state.rooms.rooms.find(r => {
            return r.profileId === val.id
          });
          state.currentRoom = profileRoom;  
          state.activeProfile.roomId = profileRoom.id;
        }
      },
      setHost(state, val) {
        state.activeProfile.host = val
      },
      setInvitesList(state, val) {
        state.invites = val;
      },
      setProfiles(state, val) {
        state.profiles = val  
      },
      setUserRooms(state, val) {
        state.rooms = val;
      },
      setSaveData(state, val) {
        state.saves = val 
      },
      setCurrentRoom(state, val) {
        state.currentRoom = val        
      },
      setPlayers(state, val) {
        state.players = val      
      },
      setWaiting(state, val) {
        state.waiting = val      
      },      
      setActivity(state, val) {   
        state.currentActivity = val;
      },
      setActivityCache(state, val) {
        state.activityCache = val 
      },
      updateSaveActivity(state, val){
        let i;
        if(val != null) {
          const isMatched = (save) => save.id === val.id;
          i = state.saves.saves.findIndex(isMatched);
          console.log("updating: " + i);
          state.saves.saves[i].content = JSON.stringify(val.content);
          state.saves.saves[i].thumbPath = val.thumbPath;
        } else {
          console.log("existing save not found");
        }
      },
      setActivityThumb(state, val) {
        state.activityCache.thumbPath = val 
      },      
      setLoadedSave(state, val) {
        state.loadedSave = val 
      },      
      setInvite(state, val) {
        state.guestData = val 
      },
      setSignUp(state, val) {
        state.signUpCodeData = val 
      },      
      setFeedback(state, val) {
        state.feedback = val 
      },
      setInactive(state, val) {
        state.inActivateTimeOut = val 
      }      
};