import * as firebase from '../services/firebase'
// TODO check if more than one is waiting
// TODO where to route them if something is wrong? Error messages?
// TODO error if the room doesn't exist?
export default async function roomCheck ({ to, from, next, store }) {
    try {
    //console.log("Running room check logic");
    if(firebase.auth.currentUser) {
        await store.dispatch('getRoom', to.params.space_id)
        .then( () => {
            let theRoom = store.getters.currentRoom;
            if(store.getters.activeProfile != null) {
                if(store.getters.activeProfile.roomId === to.params.space_id) {
                    // it's their room so they may enter
                    store.commit('setHost', true);
                    return next(); 
                } else {
                    if(theRoom.open === true) { //
                        // if the room is open let them in
                        store.commit('setHost', false);
                        return next(); 
                    } else {
                        // this will send them back to where they came if the room is closed.
                        // TODO Handle error messages
                        return next(from); 
                    }      
                }
            } else {
                // This seems unlikely, but covering odd cases.
                // TODO Handle error messages
                return next('/playspace/error'); 
            }
        });
    } else {
        let redirectPath = to.path;
        return next({ name: 'Login', query: {redirect: redirectPath} });
    } 
    } catch (error){
        console.log(error);
        return error.message;       
    }
}