import * as firebase from '../services/firebase'

export default function setupSteps ({ to, from, next, store }){
    if(to.path === "/signup/step1" && firebase.auth.currentUser){
        return next('/signup/step2');  
    } 
    else if (to.path === "/signup/step2" && firebase.auth.currentUser) {
        if(store.getters.masterProfile !== null && store.state.profiles.count !== 0) {
            if(store.state.guestData !== null) {
                return next('/selectprofile/');
            } else {
                return next('/home');
            } 
        } else {
            return next();
        }
    } 
    else if (to.path === "/signup/step3" && firebase.auth.currentUser) {
        if(store.getters.profileCount >= 6) {
            if(store.state.guestData != null){
                return next('/selectprofile/');
            } else {
                return next('/signup/onboarding');
            }     
        } else {
            return next();            
        }
    } 
    else {
        return next();
    }
}
