/* eslint-disable no-console */
import { auth, roomsCollection} from './services/firebase';
import store from './store/index';
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage') {
  register('/service-worker.js', {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.');
      // TODO force clear cache
      /*if(!auth.currentUser) {
        store.dispatch('logout');  
      }*/           
      window.location.reload(true);
    },
    updated () {
      console.log('New content is available; Refresh...');
      // TODO force clear cache
      /*if(!auth.currentUser) {
        store.dispatch('logout');  
      }*/                    
      window.location.reload(true);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
