import Vue from 'vue';
import App from './App.vue';
import VueGtag from "vue-gtag";
import router from './routes/index';
import store from './store/index';
//import sentryIO from './services/sentry';
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import {i18n} from './services/i18n'
import { auth, roomsCollection} from './services/firebase';
import { ToastPlugin, FormTimepickerPlugin, FormDatepickerPlugin, FormRadioPlugin, FormCheckboxPlugin, LayoutPlugin, ButtonPlugin } from 'bootstrap-vue'
import './assets/scss/app.scss';
import MasterLayout from '@/layouts/master';
import './registerServiceWorker';
import mixin from '@/common/mixins.js';
import browserDetect from "vue-browser-detect-plugin";
import VuePwaInstallPlugin from "vue-pwa-install";

Vue.config.productionTip = false;

Vue.use(VueGtag, {config: { id: process.env.VUE_APP_GA_ID, params: {send_page_view: false }}, useDebugger: false}, ); //process.env.VUE_APP_FIREBASE_MEASUREMENTID
Vue.use(require('vue-moment'));
Vue.use(browserDetect);
Vue.use(VuePwaInstallPlugin);
Vue.component('MasterLayout', MasterLayout);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(ToastPlugin);
Vue.mixin(mixin);
Sentry.init({
  Vue,
  dsn: "https://cfe9449ed741494f8010cc18847ab8fc@o491956.ingest.sentry.io/5558415",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  release: "playspaces-@" + store.getters.appVersion,
  environment: store.getters.currentEnvironment,
  autoSessionTracking: true,
  integrations: [new TracingIntegrations.BrowserTracing()],
  logErrors: true,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
Vue.prototype.$sentry = Sentry;
Vue.prototype.$roomsCollection = roomsCollection;

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      Sentry,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})