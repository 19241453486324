// TODO refactor cache and move loadedSave and thumbnail into activity cache
// TODO change how saves are handled and reloaded.
// TODO combined signUpCodeData and inviteGuestData into 1 object called guestData

export default {
  defaultState() {
    return {
      token: null,
      feedback: null,
      currentIpData: null,
      currentRoom: null,
      currentActivity: null, 
      activityCache: null,
      loadedSave: null,        
      userData: null,
      guestData: null,
      signUpCodeData: null,
      activeProfile: null,
      profiles: null,
      rooms: null,
      invites: null,
      players: null,
      waiting: null,
      saves: null,
      activitiesList: null,
      inActivateTimeOut: false,
      packageVersion: process.env.PACKAGE_VERSION || '0',
      currentEnvironment: process.env.VUE_APP_ENV,
      currentAppName: process.env.VUE_APP_TITLE,
      currentDomain: process.env.VUE_APP_URL 
    }
  }
}